<template>
  <div>
    <NavBar
      style="max-height: 50px; margin-bottom: 10px"
      :username="currentUserName"
      :pageName="pageName"
    ></NavBar>
    <v-app>
      <!-- dialog for adding corporate start -->
      <div>
        <v-row justify="center">
          <v-dialog v-model="createCorporateModal" persistent max-width="500">
            <v-card>
              <v-card-title class="createCorporateDilogTitle">
                <div>
                  <p style="font-weight:bold;margin-top:10px" >
                    Create Corporate
                    </p>
                </div>
                <div>
                  <v-btn  icon @click="createCorporateModal=false, overlay=false">
                  <img src="@/assets/popupClose.svg" >
                  </v-btn>
                </div>
              </v-card-title>
              <v-card-text>
                <div>
                  <p style="text-align:left; color:black">Corporate Name</p>
                </div>
                <div>
                  <v-text-field
                  v-model="corporateName"
                  placeholder="Enter the Corporate Name"
                  solo
                  :rules="[(v) => v !== ''? false: 'Required']"
                  >
                  </v-text-field>
                </div>
              </v-card-text>
              <v-card-actions style="display:flex; justify-content:flex-end">
                <div >
                  <v-btn
                  color="primary"
                  width="100px"
                  @click="createCorporate"
                  :loading="this.creatingCorporate"
                  :disabled="this.creatingCorporate"   
                  >
                  Save
                  </v-btn>
                </div>
              </v-card-actions>
              <div class="corparateNameSuccess">
                <p style="color:#219653; " v-if="corporateNameFlag">{{corporateName}} added successfully !</p>
                <p style="color:red; font-weight:500" v-else-if="corporateNameFlagErrorCode =='305'">{{corporateName}} already exists</p>
                <p style="color:red; font-weight:500 " v-else-if="corporateNameFlagError">Error</p>
              </div>
            </v-card>
          </v-dialog>
        </v-row>
      </div>
      <!-- Adding corporate Dialog End -->
      <div style="background-color: #f5faff; height: 93vh !important">
        <v-row cols="12">
          <v-col cols="2">
            <SideBar style="max-height: 50px" :pageName="pageName"></SideBar>
          </v-col>
          <v-col cols="10" style="overflow:scroll; height:90vh">
            <div style="margin:10px 20px 20px 50px">
              <div style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              vertical-align: middle;
              ">
                <!-- <v-row style="margin-left: 5px" class="pageHeaderClass"> -->
                  <div style="width: 30%" align="left">
                    <span style="
                        font-weight: 800;padding-left: 5px;
                        font-size: 12pt;
                        text-transform: uppercase;
                        display:flex;
                        padding-top: 20px;
                        letter-spacing: 1px;
                      ">
                      <h3>Corporate Program</h3>
                    </span>
                  </div>
                  <div style="display: flex;">
                    <div style="padding: 15px;">
                      <v-text-field
                        v-model="filterCorparateProgram"
                        label="Enter Corporate Program Name"
                        dense
                        solo
                      >
                        <template v-slot:append>
                          <v-btn @click="filtercorporate" icon>
                            <img
                              style="width: 25px; height: 25px"
                              src="https://d3vsnl8idgwrlw.cloudfront.net/search.svg"
                            />
                          </v-btn>
                        </template>
                      </v-text-field>
                    </div>
                    <div style="padding: 15px;">
                      <v-btn
                      @click="redirectTOCreatePackage"
                      color="primary"
                      >
                        <span style="font-size: 18pt"> + </span>
                         <span style="font-weight: 600;margin-top:2%">Create Package</span>
                      </v-btn>
                    </div>
                    <div style="padding: 15px;">
                      <v-btn
                      @click="createCorporateModal= true"
                      color="primary"
                      >
                      Add Corporate
                      </v-btn>
                    </div>
                  </div>
                <!-- </v-row> -->
              </div>
              <div v-if="!isPageLoading">
                <v-data-table
                  :headers="headers"
                  :items="allCorporatePackageData"
                  class="corporatePackageTable"
                  :search="filterCorparateProgram"
                  >
                <!-- changeStatusofCorporate -->
                  <template v-slot:item.packageStatus="{item}" >
                    <v-switch
                    :loading = "isEditLoading"
                    :disabled = "isDisabled"
                    v-model="item.packageStatus"
                    @change="changeStatusOfPackage(item)"
                    ></v-switch>
                  </template>
                  <template v-slot:item.action="{item}" >
                    <v-btn class="mx-2" icon @click="viewCorparatePackage(item)">
                      <img src="https://d3vsnl8idgwrlw.cloudfront.net/show_pwd.svg" style="height: 24px; width: 24px;"/>
                    </v-btn>
                    <v-btn v-show="false" class="mx-2" icon @click="editCorparatePackage(item)">
                      <img src="https://d3vsnl8idgwrlw.cloudfront.net/edit.svg" />
                    </v-btn>
                  </template>
                </v-data-table>
              </div>
              <div v-else>
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-app>
  </div>
</template>
<script>
import axios from "axios";
import NavBar from "../../../views/navbar.vue";
import SideBar from "../../../views/sideBar.vue";
import { handleError } from '../../../utils/utils';

export default {
  name: "AllCorporatePackages",
  data() {
    return {
      pageName: "",
      currentUser: "",
      currentUserName: "",
      filterCorparateProgram: "",
      headers: [
          {
            text: 'Company Name',
            align: 'start',
            sortable: false,
            value: 'company_name',
          },
          { text: 'Package Name', value: 'corporate_program_name' },
          { text: 'Total Employees', value: 'total_employee_count' },
          { text: 'Active Employees', value: 'active_employee_count' },
          { text: 'Inactive Employees', value: 'inactive_employee' },
          { text: 'Package Status', value: 'packageStatus' },
          { text: 'Action', value: 'action' },
        ],
      createCorporateModal:false,
      corporateName:"",
      created_by:"",
      corporateNameFlag:false,
      corporateNameFlagError:false,
      corporateNameFlagErrorCode:"",
      allCorporatePackageData:[],
      packageStatus:false,
      isPageLoading: true,
      isEditLoading: false,
      isDisabled: false,
      creatingCorporate: false,
    };
  },
  components: {
    NavBar,
    SideBar,
  },
  mounted() {
    this.pageName = "CorparatePackageHome";
    this.currentUser = this.$cookies.get("token");
    if (!this.currentUser) {
      this.$router.push({
        name: "AdminLogin",
      });
    }
    else
    {
      var getAdminBody ={
        token : this.currentUser,
        typeOfUser : "ADMIN",
        adminType : "ADMIN"
      }
      axios.post(process.env.VUE_APP_BACKEND_URL+"/getAdmin",getAdminBody)
      .then((getAdminResponse)=>{
         this.currentUserName = getAdminResponse.data.data.admin_name;
          // console.log(getAdminResponse.data.data.created_by)
        this.created_by = getAdminResponse.data.data._id
        var readAllCorporatePackagesBody ={
          token : this.currentUser,
          typeOfUser : "ADMIN",
        }
        axios.post(process.env.VUE_APP_BACKEND_URL+"/readAllCorporatePackages",readAllCorporatePackagesBody)
        .then((readAllCorporatePackagesResponse)=>{
          let responseData = readAllCorporatePackagesResponse.data.data
          responseData.forEach(element => {
            element.inactive_employee = element.total_employee_count- element.active_employee_count
            element.packageStatus = element.status == "ACTIVE" ? true : false
          });
          this.allCorporatePackageData = responseData
          this.isPageLoading = false;
        })
        .catch((readAllCorporatePackagesError)=>{
          this.isPageLoading = false;
          handleError(readAllCorporatePackagesError, this.$router, 'readAllCorporatePackagesError', 'AdminLogin', 'token');
        })
      })
      .catch((getAdminError) =>{
        if (getAdminError.response.status == 401) {
          window.alert("Sorry !! You are unauthorized !!");
          this.$router.push({
            name: "AdminLogin",
          });
        } else {
          window.alert("Something went wrong...");
        }
      });
    }
  },
  methods: {
      viewCorparatePackage(item){
        this.$router.push({
          path: "ViewCorporatePackage/"+item._id,
        });
      },
      editCorparatePackage(item){
          console.log("Edit Corparate Package")
          this.$router.push({
            path: "/admin/EditBenefitsCorporatePackage/"+item._id,
          });
      },
      redirectTOCreatePackage(){
      this.$router.push({
        name: "CreateCorporatePackage",
      });
      },
      filtercorporate(){
        console.log("Filter Corporate")
      },
      createCorporate(){
          var createCorporateMasterBody ={
            companyName:this.corporateName,
            createdBy:this.created_by,
            token : this.currentUser,
            typeOfUser : "ADMIN",
          }
          if (this.corporateName === "") {
            return;
          }
          this.creatingCorporate = true;
          axios.post(process.env.VUE_APP_BACKEND_URL+"/createCorporateMaster",createCorporateMasterBody)
          .then((createCorporateMasterResponse)=>{
            this.corporateNameFlag= true
            console.log(createCorporateMasterResponse)
          this.creatingCorporate = false;
            setTimeout(() => {
              this.createCorporateModal = false
              this.corporateName=""
              this.corporateNameFlag= false
            }, 3000);
          })
          .catch((createCorporateMasterError) =>{
            this.creatingCorporate = false;
            this.corporateNameFlagErrorCode = createCorporateMasterError.response.status;
            this.corporateNameFlagError = true
            console.log(createCorporateMasterError)
          })
      },
      changeStatusOfPackage(item){
        this.isEditLoading = true;
        this.isDisabled = true;
        console.log("Status Change", item)
        var changeCorporatePackageStatusBody = {
          corporatePackageId:item._id,
          status: item.packageStatus == true ? "ACTIVE" : "INACTIVE",
          updatedBy: this.created_by,
          token : this.currentUser,
          typeOfUser : "ADMIN",
        }
        axios.post(process.env.VUE_APP_BACKEND_URL+"/changeCorporatePackageStatus",changeCorporatePackageStatusBody)
        .then((changeCorporatePackageStatusResponse)=>{
          // console.log("changeCorporatePackageStatusResponse", changeCorporatePackageStatusResponse)
          this.isEditLoading = false;
          this.isDisabled = false;
        })
        .catch((changeCorporatePackageStatusError)=>{
          window.alert("Something went worng in changing corporate package status")
          this.isEditLoading = false;
          this.isDisabled = false;
          handleError(changeCorporatePackageStatusError, this.$router, 'changeCorporatePackageStatusError', 'AdminLogin', 'token');
        })
      },
  },
};
</script>
<style scoped>
.pageHeaderClass {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.createCorporateDilogTitle{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.corparateNameSuccess{
  padding: 8px;
  text-transform: uppercase;
}
</style>
<style >
.corporatePackageTable .v-data-table-header th{
  background: #1467bf !important;
  color: white !important;
  font-size: 8pt !important;
  font-weight: 500 !important;
  padding-left:5px !important;
}
.corporatePackageTable tbody td{
  font-size: 8pt !important;
  font-weight: 700 !important;
  padding-left:5px !important;
}

</style>